import React from 'react';
import PropTypes from 'prop-types';
import i18next from "i18next";
import ArrowRightWhite from "../../img/arrow-right-white.png";
import Utils from '../../helpers/Utils';

export default class LoginForm extends React.Component {
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			login: props.login,
			password: props.password,
			as_id: null,
			own: false,
			useOwn: Utils.isLoc(),
		};
		
	}
	
	inputChange = (event) => {
		
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.getAttribute('name');
		
		this.setState({
			[name]: value
		});
		
	};
	
	getFormData = () => {
		return this.state;
	};
	
	submit = (event) => {
		event.preventDefault();
		this.props.submit(this.getFormData());
	};
	
	render() {
		return (
			<form className={'LoginForm'} onSubmit={this.submit}>
				
				<div className={'form-group'}>
					<label htmlFor="login">{i18next.t("Login")}</label>
					<input type={'text'}
						   id={'login'}
						   name={'login'}
						   //placeholder={i18next.t("Login")}
						   value={this.state.login}
						   className={'form-control my-form-control my-p-25'}
						   onChange={this.inputChange}
						   autoFocus
						   required
					/>
				</div>
				
				<div className={'form-group'}>
					<label htmlFor="password">{i18next.t("Password")}</label>
					<input type="password"
						   id={'password'}
						   name={'password'}
						   //placeholder={i18next.t("Password")}
						   value={this.state.password}
						   className={'form-control my-form-control my-p-25'}
						   onChange={this.inputChange}
						   required
					/>
				</div>
				
				{(this.state.useOwn) && (
					
					<>
					
						<div className={`form-group mb-0`}>
							
							<label
								htmlFor={`own`}
								className={`form-label`}
							>
								<input
									id={`own`}
									name={`own`}
									type={'checkbox'}
									checked={this.state.own}
									onChange={this.inputChange}
									// className={`form-control`}
									required={false}
								/> {i18next.t("Debug")}
							</label>
							
						</div>
						
						{(this.state.own) && (
							
							<div className={`own`}>
						
								<div className={`form-group mt-0`}>
									
									{/* <label
										htmlFor={`as_id`}
										className={`form-label`}
									>{i18next.t("As ID")}</label> */}
									
									<input
										id={`as_id`}
										name={`as_id`}
										className={`form-control my-form-control my-p-25`}
										type={`number`}
										min={1}
										step={1}
										value={this.state.as_id}
										placeholder={'User ID'}
										onChange={this.inputChange}
										required={false}
									/>
									
								</div>
								
							</div>
							
						)}
						
					</>
					
				)}
				
				<div className="controls">
					
					<button
						type={'button'}
						className={'btn submit-btn my-btn my-btn-primary demo-btn'}
						onClick={(event) => {
							this.props.startDemo(event)
						}}
					>{i18next.t("Demo")}</button>
					
					<button type={'submit'} className={'btn submit-btn my-btn my-btn-primary'}>
						{i18next.t("Log in")} <img className={'icon'} src={ArrowRightWhite} alt=""/>
					</button>
					
				</div>
			
			</form>
		);
	}
	
}

LoginForm.propTypes = {
	submit: PropTypes.func.isRequired,
	login: PropTypes.string,
	password: PropTypes.string,
	startDemo: PropTypes.func,
};

LoginForm.defaultProps = {
	login: '',
	password: '',
	startDemo: (event) => {
		window.alert('start demo')
	}
};